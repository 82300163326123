exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-internet-issues-js": () => import("./../../../src/pages/internet-issues.js" /* webpackChunkName: "component---src-pages-internet-issues-js" */),
  "component---src-pages-loginauth-js": () => import("./../../../src/pages/loginauth.js" /* webpackChunkName: "component---src-pages-loginauth-js" */),
  "component---src-pages-myexpenses-js": () => import("./../../../src/pages/myexpenses.js" /* webpackChunkName: "component---src-pages-myexpenses-js" */),
  "component---src-pages-mypay-js": () => import("./../../../src/pages/mypay.js" /* webpackChunkName: "component---src-pages-mypay-js" */),
  "component---src-pages-mywork-js": () => import("./../../../src/pages/mywork.js" /* webpackChunkName: "component---src-pages-mywork-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-timeclock-js": () => import("./../../../src/pages/timeclock.js" /* webpackChunkName: "component---src-pages-timeclock-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-pages-welcome-js": () => import("./../../../src/pages/welcome.js" /* webpackChunkName: "component---src-pages-welcome-js" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

