module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Atlas-Application","short_name":"Atlas_App","description":"This is the offline capable application for Atlas","start_url":"/mywork","background_color":"#0a68f0","theme_color":"#0a68f0","display":"standalone","icon":"src/images/Atlas_Logo.png","icons":[{"src":"/favicons/Atlas_Logo-192x192.png","sizes":"192x192","type":"image/png"},{"src":"/favicons/Atlas_Logo-512x512.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"63553530f6e152d55c75a3d9f5a87b5c"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"precachePages":["/internet-issues/*","/projects/*","/mywork/*","/timeclock/*","/myexpenses/*","/mypay/*"],"appendScript":"/opt/buildhome/repo/src/custom-sw-code.js"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
